import React, { useState, useEffect } from "react";
import { Link, navigate } from "gatsby";
import styled from "styled-components";
import { useRadioGroupState } from "@react-stately/radio";
import { useRadio, useRadioGroup } from "@react-aria/radio";
import { VisuallyHidden } from "@react-aria/visually-hidden";
import { useFocusRing } from "@react-aria/focus";
import { AnimatePresence, motion } from "framer-motion";
import {
  sizes,
  colorAliases,
  fontSizes,
  borderRadius,
  boxShadow,
  MOBILEBREAKPOINT,
} from "../style/design-system";
import OuterContainer from "./OuterContainer";
import Burger from "./Burger";
import Close from "./Close";
import LanguageSwitcherButton from "./LanguageSwitcherButton";

const InnerContainer = styled.div`
  width: 100%;
  padding: ${sizes["lg"]};
  margin: 0 auto;
  max-width: ${sizes.siteWidth};
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: baseline;

  @media (max-width: ${MOBILEBREAKPOINT}) {
    display: flex;
    justify-content: space-between;
  }
`;

const SiteTitle = styled.h1`
  color: ${colorAliases.turqoise};
  font-size: ${fontSizes.logo};
  font-weight: 400;
  width: fit-content;

  & a {
    color: inherit;
  }
`;

const RegularNav = styled.nav`
  @media (max-width: ${MOBILEBREAKPOINT}) {
    display: none;
  }
`;

const NavLinks = styled.ul`
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
  padding: 0;

  @media (max-width: ${MOBILEBREAKPOINT}) {
    flex-direction: column;
    justify-content: center;
    margin: ${sizes["2xl"]} 0 ${sizes["2xl"]} 0;
    padding: 0 ${sizes.lg};
  }
`;

const NavItem = styled.li`
  list-style: none;
  margin-right: ${sizes.sm};
  position: relative;

  &:last-of-type {
    margin-right: 0;
  }

  @media (max-width: ${MOBILEBREAKPOINT}) {
    font-size: ${fontSizes.xl};
    font-weight: 400;
    margin: 0 0 ${sizes.lg};

    &:last-of-type {
      margin: 0;
    }
  }
`;

const NavLink = styled(Link)`
  color: ${colorAliases.textColor};
  font-size: ${fontSizes.lg};

  &:hover {
    text-decoration: underline;
  }

  @media (max-width: ${MOBILEBREAKPOINT}) {
    font-size: ${fontSizes["2xl"]};
  }
`;

const NavLinkExternal = styled.a`
  color: ${colorAliases.textColor};
  font-size: ${fontSizes.lg};

  &:hover {
    text-decoration: underline;
  }

  @media (max-width: ${MOBILEBREAKPOINT}) {
    font-size: ${fontSizes["2xl"]};
  }
`;

const LoginSignup = styled.div`
  display: flex;
  @media (max-width: ${MOBILEBREAKPOINT}) {
    display: none;
  }
`;

const LoginButtonLink = styled.a`
  background: transparent;
  border-radius: ${borderRadius.md};
  border: 1px solid ${colorAliases.turqoise};
  color: ${colorAliases.turqoise};
  padding: ${sizes.xs} ${sizes.md};
  font-size: ${fontSizes.lg};
  box-shadow: ${boxShadow.md};
  margin-left: ${sizes.xs};
  cursor: pointer;

  ${OuterContainer} & {
    color: ${colorAliases.turqoise}
    letter-spacing: 0.2px;
  }
`;

const StyledBurger = styled(Burger)`
  width: 40px;
  cursor: pointer;
  transform: translate(4px, 10px);

  @media (min-width: ${MOBILEBREAKPOINT}) {
    display: none;
  }
`;

const MobileNav = styled(motion.nav)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 4;
  background: white;
  grid-column: 3 / 4;
`;

const StyledClose = styled(Close)`
  position: absolute;
  width: 40px;
  top: 24px;
  right: 24px;
  cursor: pointer;
`;

const Overlay = styled.div`
  background: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;
`;

const LanguagesBox = styled.div`
  padding: ${sizes.sm};
  position: ${(p) => (p.mobile ? `inherit` : `absolute`)};
  z-index: 1;
`;

const LanguageItem = styled.div`
  cursor: pointer;
`;

const navItems = {
  no: {
    home: {
      link: `/`,
    },
    pricing: {
      text: `Priser`,
      link: `/priser/`,
    },
    articles: {
      text: `Artikler`,
      link: `/artikler/`,
    },
    faq: {
      text: `FAQ`,
      link: `/faq/`,
    },
    about: {
      text: `Om`,
      link: `/om/`,
    },
    contact: {
      text: `Kontakt`,
      link: `/kontakt/`,
    },
    language: {
      text: `Språk`,
      label: `Velg språk`,
      link: `/no/`,
      options: [
        {
          text: `Engelsk`,
          current: false,
          link: `/`,
        },
        {
          text: `Norsk`,
          current: true,
          link: `/no/`,
        },
        {
          text: `Svensk`,
          current: false,
          link: `/se/`,
        },
      ],
    },
  },
  se: {
    home: {
      link: `/se/`,
    },
    pricing: {
      text: `Priser`,
      link: `/se/priser/`,
    },
    articles: {
      text: `Artiklar`,
      link: `/se/artiklar/`,
    },
    faq: {
      text: `FAQ`,
      link: `/se/faq/`,
    },
    about: {
      text: `Om oss`,
      link: `/se/om-oss/`,
    },
    contact: {
      text: `Kontakt`,
      link: `/se/kontakt/`,
    },
    language: {
      text: `Språk`,
      label: `Välj språk`,
      link: `/se/`,
      options: [
        {
          text: `Engelska`,
          current: false,
          link: `/`,
        },
        {
          text: `Norska`,
          current: false,
          link: `/no/`,
        },
        {
          text: `Svenska`,
          current: true,
          link: `/se/`,
        },
      ],
    },
  },
  en: {
    home: {
      link: `/`,
    },
    pricing: {
      text: `Pricing`,
      link: `/pricing/`,
    },
    articles: {
      text: `Articles`,
      link: `/articles/`,
    },
    faq: {
      text: `FAQ`,
      link: `/faq/`,
    },
    about: {
      text: `About`,
      link: `/about/`,
    },
    contact: {
      text: `Contact`,
      link: `/contact/`,
    },
    language: {
      text: `Language`,
      label: `Select language`,
      link: `/`,
      options: [
        {
          text: `English`,
          current: true,
          link: `/`,
        },
        {
          text: `Norwegian`,
          current: false,
          link: `/no/`,
        },
        {
          text: `Swedish`,
          current: false,
          link: `/se/`,
        },
      ],
    },
  },
};

const i18n = {
  login: {
    no: `Logg inn`,
    se: `Logga in`,
    en: `Log in`,
  },
};

let RadioContext = React.createContext();

function RadioGroup(props) {
  let { children, label } = props;
  let state = useRadioGroupState(props);
  let { radioGroupProps, labelProps } = useRadioGroup(props, state);

  return (
    <div {...radioGroupProps}>
      <span {...labelProps}>{label}</span>
      <RadioContext.Provider value={state}>{children}</RadioContext.Provider>
    </div>
  );
}

function Radio(props) {
  let { children, index } = props;
  let state = React.useContext(RadioContext);
  let ref = React.useRef(null);
  let { inputProps } = useRadio(props, state, ref);
  let { isFocusVisible, focusProps } = useFocusRing();

  let isSelected = state.selectedValue === props.value;
  let strokeWidth = isSelected ? 6 : 2;

  return (
    <motion.label
      variants={{
        hidden: (index) => ({
          opacity: 0,
          y: -30 * index,
        }),
        visible: (index) => ({
          opacity: 1,
          y: 0,
          transition: {
            delay: index * 0.025,
          },
        }),
        remove: (index) => ({
          opacity: 0,
          // y: -30 * index,
          // transition: {
          //   delay: index * 0.05,
          // },
        }),
      }}
      custom={index}
      initial="hidden"
      animate="visible"
      exit="remove"
      style={{
        display: "flex",
        alignItems: "center",
        marginBottom: 4,
        cursor: "pointer",
      }}
    >
      <VisuallyHidden>
        <input {...inputProps} {...focusProps} ref={ref} />
      </VisuallyHidden>
      <svg width={24} height={24} aria-hidden="true" style={{ marginRight: 4 }}>
        <circle
          cx={12}
          cy={12}
          r={8 - strokeWidth / 2}
          fill="none"
          stroke={
            isSelected ? colorAliases.turqoise : colorAliases.turqoiseLight
          }
          strokeWidth={strokeWidth}
        />
        {isFocusVisible && (
          <circle
            cx={12}
            cy={12}
            r={11}
            fill="none"
            stroke={colorAliases.turqoiseLight}
            strokeWidth={2}
          />
        )}
      </svg>
      {children}
    </motion.label>
  );
}

const Navbar = ({ language }) => {
  const [open, setOpen] = useState(false);
  const [lang, setLang] = useState(navItems[language]);
  const [languageOpen, setLanguageOpen] = useState(false);

  useEffect(() => {
    setLang(navItems[language]);
  }, [language, setLang]);

  const handleChange = (link) => {
    navigate(link);
  };

  return (
    <OuterContainer>
      <InnerContainer>
        <SiteTitle>
          <Link to={lang.home.link}>dflow</Link>
        </SiteTitle>

        <RegularNav>
          <NavLinks>
            <NavItem>
              <NavLink to={lang.pricing.link}>{lang.pricing.text}</NavLink>
            </NavItem>
            {/* <NavItem>
              <NavLink to={lang.articles.link}>{lang.articles.text}</NavLink>
            </NavItem> */}
            {/* <NavItem>
              <NavLink to={lang.faq.link}>{lang.faq.text}</NavLink>
            </NavItem> */}
            <NavItem>
              <NavLink to={lang.about.link}>{lang.about.text}</NavLink>
            </NavItem>
            <NavItem>
              <NavLink to={lang.contact.link}>{lang.contact.text}</NavLink>
            </NavItem>
            {/* <NavItem>
              <LanguageSwitcherButton toggle={setLanguageOpen}>
                {lang.language.text}
              </LanguageSwitcherButton>
              {languageOpen && (
                <LanguagesBox>
                  <RadioGroup
                    defaultValue={lang.language.link}
                    onChange={handleChange}
                    aria-label={lang.language.label}
                  >
                    {" "}
                    <AnimatePresence>
                      {lang.language.options.map((lang, index) => (
                        <Radio value={lang.link} key={lang.text} index={index}>
                          <LanguageItem>
                            <span>{lang.text}</span>
                          </LanguageItem>
                        </Radio>
                      ))}
                    </AnimatePresence>
                  </RadioGroup>
                </LanguagesBox>
              )}
            </NavItem> */}
            <NavItem>
              <LoginSignup>
                <LoginButtonLink
                  href="https://app.dflow.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: colorAliases.turqoise }}
                >
                  {i18n.login[language]}
                </LoginButtonLink>
              </LoginSignup>
            </NavItem>
          </NavLinks>
        </RegularNav>

        <StyledBurger
          onClick={() => setOpen(true)}
          color={colorAliases.textColor}
        ></StyledBurger>

        {open && (
          <AnimatePresence>
            <MobileNav
              variants={{
                hidden: {
                  opacity: 0,
                  y: -300,
                },
                visible: {
                  opacity: 1,
                  y: 0,
                },
                remove: {
                  opacity: 0,
                },
              }}
              initial="hidden"
              animate="visible"
              exit="remove"
            >
              <StyledClose
                onClick={() => setOpen(false)}
                color={colorAliases.textColor}
              ></StyledClose>

              <NavLinks>
                <NavItem>
                  <NavLink to={lang.pricing.link}>{lang.pricing.text}</NavLink>
                </NavItem>
                {/* <NavItem>
                  <NavLink to={lang.articles.link}>
                    {lang.articles.text}
                  </NavLink>
                </NavItem> */}
                {/* <NavItem>
                  <NavLink to={lang.faq.link}>{lang.faq.text}</NavLink>
                </NavItem> */}
                <NavItem>
                  <NavLink to={lang.about.link}>{lang.about.text}</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to={lang.contact.link}>{lang.contact.text}</NavLink>
                </NavItem>
                {/* <NavItem>
                  <LanguageSwitcherButton toggle={setLanguageOpen}>
                    {lang.language.text}
                  </LanguageSwitcherButton>
                  {languageOpen && (
                    <LanguagesBox mobile>
                      <RadioGroup
                        defaultValue={lang.language.link}
                        onChange={handleChange}
                        aria-label={lang.language.label}
                      >
                        {" "}
                        <AnimatePresence>
                          {lang.language.options.map((lang, index) => (
                            <Radio
                              value={lang.link}
                              key={lang.text}
                              index={index}
                            >
                              <LanguageItem>
                                <span>{lang.text}</span>
                              </LanguageItem>
                            </Radio>
                          ))}
                        </AnimatePresence>
                      </RadioGroup>
                    </LanguagesBox>
                  )}
                </NavItem> */}
                <NavItem>
                  <NavLinkExternal
                    href="https://app.dflow.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {i18n.login[language]}
                  </NavLinkExternal>
                </NavItem>
              </NavLinks>
            </MobileNav>
          </AnimatePresence>
        )}
        {open && <Overlay onClick={() => setOpen(false)}></Overlay>}
      </InnerContainer>
    </OuterContainer>
  );
};

export default Navbar;
