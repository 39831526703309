import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import {
  sizes,
  colorAliases,
  fontSizes,
  borderRadius,
  MOBILEBREAKPOINT,
} from "../style/design-system";
import OuterContainer from "./OuterContainer";

const InnerContainer = styled.div`
  width: 100%;
  padding: ${sizes["2xl"]} ${sizes["lg"]};
  margin: 0 auto;
  max-width: ${sizes.siteWidth};
`;

const CtaSection = styled.div`
  text-align: center;
`;

const CtaHeader = styled.h3`
  font-weight: 700;
  font-size: ${fontSizes["4xl"]};
  color: white;
`;
const CtaSecondHeader = styled.h4`
  color: white;
  font-size: ${fontSizes.xl};
  margin-bottom: ${sizes["2xl"]};
  font-weight: 300;
`;

const CtaInput = styled.input`
  border: none;
  padding: ${sizes.md} ${sizes.xl};
  color: ${colorAliases.textColor};
  border-radius: ${borderRadius.md};
  margin: 0 auto ${sizes.md} auto;
  font-size: ${fontSizes.lg};
  width: 400px;

  @media (max-width: ${MOBILEBREAKPOINT}) {
    width: 100%;
  }
`;

const CtaButton = styled.button`
  background: ${colorAliases.red};
  border-radius: ${borderRadius.md};
  border: none;
  color: white;
  padding: ${sizes.md} ${sizes.xl};
  font-size: ${fontSizes.lg};
  cursor: pointer;
  margin: 0 auto ${sizes["2xl"]} auto;
  width: 400px;

  @media (max-width: ${MOBILEBREAKPOINT}) {
    width: 100%;
  }
`;

const InfoContainer = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: ${MOBILEBREAKPOINT}) {
    flex-direction: column;
  }
`;

const InfoBox = styled.div`
  margin-right: ${sizes["2xl"]};

  &:last-of-type {
    margin-right: 0;
  }
`;

const InfoBoxHeader = styled.h4`
  font-weight: 700;
  color: white;
  margin-bottom: ${sizes.md};
`;

const InfoBoxList = styled.ul`
  padding: 0;
`;

const InfoBoxItem = styled.li`
  list-style: none;
`;

const InfoBoxLink = styled.a`
  color: white;
  text-decoration: none;

  &:hover {
    color: white;
    text-decoration: underline;
  }

  &:visited {
    color: white;
  }
`;
const InfoBoxLinkInternal = styled(Link)`
  color: white;
  text-decoration: none;

  &:hover {
    color: white;
    text-decoration: underline;
  }

  &:visited {
    color: white;
  }
`;
const InfoBoxItemText = styled.p`
  color: white;
  margin: 0;
  line-height: 1.15;
`;

const LastLine = styled.p`
  color: ${colorAliases.turqoiseLight};
`;

const Navbar = () => (
  <OuterContainer backGroundColor={colorAliases.turqoise}>
    <InnerContainer>
      {/* <CtaSection>
        <CtaHeader>
          Klar for å digitalisere dokumenter og signeringer?
        </CtaHeader>
        <CtaSecondHeader>
          Vi hjelper deg mot en enklere hverdag.
        </CtaSecondHeader>
        <CtaInput placeholder="Din epost..."></CtaInput>
        <br></br>
        <CtaButton>Begynn med dflow i dag</CtaButton>
      </CtaSection> */}

      <InfoContainer>
        <InfoBox>
          <InfoBoxHeader>Andre produkter</InfoBoxHeader>

          <InfoBoxList>
            <InfoBoxItem>
              <InfoBoxLink
                href="https://dib.no/produkter/dib"
                target="_blank"
                rel="noopener noreferrer"
              >
                dib
              </InfoBoxLink>
            </InfoBoxItem>

            <InfoBoxItem>
              <InfoBoxLink
                href="https://dib.no/produkter/kontohjelp/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Kontohjelp
              </InfoBoxLink>
            </InfoBoxItem>

            <InfoBoxItem>
              <InfoBoxLink
                href="https://dib.no/produkter/kostrahjelp/"
                target="_blank"
                rel="noopener noreferrer"
              >
                KOSTRAhjelp
              </InfoBoxLink>
            </InfoBoxItem>
          </InfoBoxList>
        </InfoBox>

        <InfoBox>
          <InfoBoxHeader>Lenker</InfoBoxHeader>

          <InfoBoxList>
            <InfoBoxItem>
              <InfoBoxLinkInternal to="/artikler">Artikler</InfoBoxLinkInternal>
            </InfoBoxItem>

            <InfoBoxItem>
              <InfoBoxLinkInternal to="/om">Om oss</InfoBoxLinkInternal>
            </InfoBoxItem>

            <InfoBoxItem>
              <InfoBoxLinkInternal to="/kontakt">
                Kontakt oss
              </InfoBoxLinkInternal>
            </InfoBoxItem>

            <InfoBoxItem>
              <InfoBoxLinkInternal to="/priser">Priser</InfoBoxLinkInternal>
            </InfoBoxItem>

            <InfoBoxItem>
              <InfoBoxLinkInternal to="/faq">FAQ</InfoBoxLinkInternal>
            </InfoBoxItem>

            <InfoBoxItem>
              <InfoBoxLinkInternal to="/kunder">Kunder</InfoBoxLinkInternal>
            </InfoBoxItem>
          </InfoBoxList>
        </InfoBox>

        <InfoBox>
          <InfoBoxHeader>DIBkunnskap AS</InfoBoxHeader>

          <InfoBoxList>
            <InfoBoxItem>
              <InfoBoxItemText>NO974379511</InfoBoxItemText>
            </InfoBoxItem>
            <InfoBoxItem>
              <InfoBoxItemText>+47 95407080</InfoBoxItemText>
            </InfoBoxItem>
            <InfoBoxItem>
              <InfoBoxItemText>post@dib.no</InfoBoxItemText>
            </InfoBoxItem>
          </InfoBoxList>
        </InfoBox>
      </InfoContainer>
    </InnerContainer>
  </OuterContainer>
);

export default Navbar;
