import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";

export default ({
  className,
  translateX = 0,
  color,
  onClick = () => {},
  isSelected,
}) => {
  const [rotation, setRotation] = useState(0);

  useEffect(() => {
    if (isSelected) setRotation(450);
    else setRotation(0);
  }, [isSelected]);

  return (
    <motion.svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="18px"
      height="18px"
      fill={color}
      className={className}
      onClick={onClick}
      initial={{
        rotate: 0,
        translateX,
        translateY: 3,
      }}
      animate={{
        rotate: rotation,
        translateX,
        translateY: 3,
      }}
      transition={{
        duration: 0.3,
        type: "spring",
        stiffness: 700,
        damping: 40,
        delay: 0.1,
      }}
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M8 5v14l11-7z" />
    </motion.svg>
  );
};
