import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import useSiteMetadata from "./SiteMetadata";
import { withPrefix } from "gatsby";
import styled from "styled-components";
import { GlobalStyles } from "./GlobalStyles";

const PageContainer = styled.div`
  min-height: calc(100vh - 388px);
`;

const langs = {
  no: `nb`,
  en: `en`,
  se: `se`,
};

const TemplateWrapper = ({ children, language = `en` }) => {
  const { title, description } = useSiteMetadata();
  const [lang, setLang] = useState(langs[language]);

  useEffect(() => {
    setLang(langs[language]);
  }, [language]);

  return (
    <div>
      <Helmet>
        <html lang={lang} />
        <title>{title}</title>
        <meta name="description" content={description} />
        <link
          rel="stylesheet"
          href="https://use.typekit.net/fbe6uci.css"
        ></link>
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix("/")}img/favicon-32x32.png`}
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix("/")}img/favicon-16x16.png`}
          sizes="16x16"
        />
        <meta name="theme-color" content="#fff" />

        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="/" />
      </Helmet>
      <Navbar language={language} />
      <PageContainer>{children}</PageContainer>
      <Footer language={language} />
    </div>
  );
};

export default TemplateWrapper;
